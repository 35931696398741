












































  import { Component, Vue, Prop } from 'nuxt-property-decorator'

  @Component
  export default class OneToolbarList extends Vue {
    name: string = 'one-toolbar-list'

    active: boolean = false
    @Prop({
      type: Number,
    })
    currentPage?: number

    @Prop({
      type: Number,
    })
    perPage?: number

    @Prop({
      type: Number,
    })
    totalItems?: number

    @Prop({
      type: Number,
    })
    totalPages?: number

    @Prop({
      type: Array,
      required: true,
    })
    sortingOptions?: Array<any>

    @Prop({
      type: String,
      required: true,
    })
    initialSorting?: string

    @Prop({
      type: String,
      required: true,
    })
    productsLayout!: string

    setProductsLayout(layoutType: string) {
      this.$emit('layout-change', layoutType)
    }
  }
